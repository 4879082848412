<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import CalendarApiService from "@/services/calendarService";
import DeletionModal from "../../../../components/widgets/deletion-modal.vue"

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "templates",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    DeletionModal
  },
  data() {
    return {
      title: "Templates",
      items: [
        {
          text: "configuration",
          href: "/configuration",
        },
        {
          text: "",
          active: true,
        },
      ],
      isLoading: false,
      templates: [],
      templateIdPublic: null,
      templateIndex: null
    };
  },
  created() {
    this.isLoading = true;
    CalendarApiService.getAllTemplatesList().then((response) => {
      this.templates = response.data.data.sort((a, b) => {
        const nameA = a.name.toUpperCase(); 
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.isLoading = false;
    });
  },
  mounted() {},
  methods: {
    showDeletionModal(idPublic, index) {
      this.$refs.templateDeleteModal.show().then(() => {
        this.isLoading = true;
        CalendarApiService.deleteTemplate(idPublic).then(() => {
          this.templates.splice(index, 1);
          this.isLoading = false;
        })
      })
    },
    editTemplate(templateIdPublic) {
      this.$router.push({
        name: "editTemplate",
        params: { templateIdPublic: templateIdPublic },
      });
    },
    createTemplate() {
      this.$router.push({ name: "createTemplate" });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('general.templates')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3" style="display: flex">
                <p class="card-title-desc" style="margin-bottom: 0">
                  {{ $t("general.templatesListDescription") }}
                </p>
                <b-button
                  style="margin-left: auto"
                  @click="createTemplate()"
                  variant="primary"
                  >{{ $t("general.addTemplate") }}</b-button
                >
              </div>
            </div>

            <div class="table-responsive">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                color="#f1b44c"
                :width="70"
                :height="70"
                loader="dots"
                :is-full-page="false"
              >
              </loading>
              <table class="table mb-0 table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("general.name") }}</th>
                    <th style="width: 100px; text-align: center">
                      {{ $t("general.action") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(template, index) in templates" :key="index">
                    <td>{{ template.name }}</td>
                    <td style="text-align: center">
                      <b-dropdown
                        dropleft
                        class="card-drop"
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none p-0"
                        no-caret
                      >
                        <template #button-content>
                          <i class="fas fa-ellipsis-h" />
                        </template>

                        <b-dropdown-item
                          @click="editTemplate(template.idPublic)"
                        >
                          <i class="fas fa-pencil-alt text-success mr-1"></i>
                          {{ $t("general.edit") }}
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click="showDeletionModal(template.idPublic, index)"
                        >
                          <i class="fas fa-trash-alt text-danger mr-1"></i>
                          {{ $t("general.delete") }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletionModal ref="templateDeleteModal" :content="this.$t('general.deleteTemplateContent')" :title="this.$t('general.deleteTemplateTitle')"/>
  </Layout>
</template>
